import c from '@/styles/Hamburger';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface HamburgerToggleProps extends ComponentProps<'input'> {
  variant?: ClassNameProp<'default'>;
}

export const HamburgerToggleComponent: Component<HamburgerToggleProps> = ({ className, variant, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('toggle_variant', variant, 'default');
  const componentClassName = cn(variantClassName, className);

  return (
    <input className={componentClassName} aria-label="Knapp för att öppna meny med länkar" type="checkbox" {...props} />
  );
};
