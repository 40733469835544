import { Button, ButtonProps } from '@/components/Button';
import c from '@/styles/Navigation';
import { StandaloneComponent } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface NavigationButtonProps extends ButtonProps {}

export const NavigationButtonComponent: StandaloneComponent<NavigationButtonProps> = ({
  content,
  options,
  ...props
}) => {
  const { colors, size, variant, className, ...baseProps } = options ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('button_colors', colors, 'primary');
  const sizeClassName = getPropStyles('button_size', size, 'small');
  const variantClassName = getPropStyles('button_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <Button
      options={{
        colors,
        size: size ?? 'small',
        variant,
        className: componentClassName,
        type: 'button',
        ...baseProps,
      }}
      {...{ content }}
      {...props}
    />
  );
};
