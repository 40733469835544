import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type FooterRowProps = ComponentProps<'div'>;

export const FooterRowComponent: Component<FooterRowProps> = ({ variant, size, colors, children, ...props }) => {
  const className = componentClassName('row', { variant, size, colors });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
