import c from '@/styles/Navigation';
import { ClassNameProp, ComponentProps, ComponentWithRef } from '@/types/component';
import { useComponentsState } from 'lib/hooks/useComponentsState';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { forwardRef } from 'react';
import { NavigationBarComponent } from './Navigation.Bar';
import { NavigationBarGroupComponent } from './Navigation.Bar.Group';
import { NavigationButtonComponent } from './Navigation.Button';
import { NavigationDrawerComponent } from './Navigation.Drawer';
import { NavigationDrawerToggleComponent } from './Navigation.Drawer.Toggle';
import { NavigationLinkComponent } from './Navigation.Link';
import { NavigationLogoComponent } from './Navigation.Logo';
import { NavigationMenuComponent } from './Navigation.Menu';
import { NavigationMenuGroupComponent } from './Navigation.Menu.Group';
import { NavigationSearchComponent } from './Navigation.Search';
import { NavigationSearchToggleComponent } from './Navigation.Search.Toggle';
import { NavigationSoMeComponent } from './Navigation.SoMe';
import { NavigationSoMeGroupComponent } from './Navigation.SoMe.Group';
import { NavigationSoMeHeadlineComponent } from './Navigation.SoMe.Headline';
import { NavigationTextFieldComponent } from './Navigation.TextField';

export interface NavigationProps extends ComponentProps<'section'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

const NavigationComponent: ComponentWithRef<HTMLElement, NavigationProps> = forwardRef(
  ({ children, className, colors, size, variant, ...props }, ref) => {
    const {
      Navigation: {
        $drawer: { isVisible: isOpen },
      },
    } = useComponentsState();

    const getPropStyles = getPropStylesFactory(c);

    const colorsClassName = getPropStyles('colors', colors);
    const sizeClassName = getPropStyles('size', size);
    const variantClassName = getPropStyles('variant', variant);
    const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

    return (
      <section className={componentClassName} data-open={isOpen ? '' : undefined} {...props} ref={ref}>
        {children}
      </section>
    );
  }
);

export const Navigation = Object.assign(NavigationComponent, {
  Bar: Object.assign(NavigationBarComponent, {
    Group: NavigationBarGroupComponent,
  }),
  Button: NavigationButtonComponent,
  Drawer: Object.assign(NavigationDrawerComponent, {
    Toggle: NavigationDrawerToggleComponent,
  }),
  Link: NavigationLinkComponent,
  Logo: NavigationLogoComponent,
  Menu: Object.assign(NavigationMenuComponent, {
    Group: NavigationMenuGroupComponent,
  }),
  Search: Object.assign(NavigationSearchComponent, {
    Toggle: NavigationSearchToggleComponent,
  }),
  SoMe: Object.assign(NavigationSoMeComponent, {
    Group: NavigationSoMeGroupComponent,
    Headline: NavigationSoMeHeadlineComponent,
  }),
  TextField: NavigationTextFieldComponent,
});
