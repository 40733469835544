import { Hamburger, HamburgerProps } from '@/components/Hamburger';
import c from '@/styles/Navigation';
import { StandaloneComponent } from '@/types/component';
import { useComponentsState } from 'lib/hooks/useComponentsState';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface NavigationDrawerToggleProps extends Omit<HamburgerProps, 'isOpen'> {}

// TODO: fix after refactoring `Navigation` component
export const NavigationDrawerToggleComponent: StandaloneComponent<NavigationDrawerToggleProps> = ({
  // @ts-expect-error
  children,
  options,
  ...props
}) => {
  const {
    Navigation: {
      $drawer: { isVisible: isOpen, toggle: onChange },
    },
  } = useComponentsState();

  const { $segment, className, ...baseProps } = options ?? {};
  const { className: segmentClassName, ...segmentProps } = $segment ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const segmentAnimationClassName = getPropStyles('drawer_toggle_segment_animation', segmentProps.animation);
  const segmentColorsClassName = getPropStyles('drawer_toggle_segment_colors', segmentProps.colors);
  const segmentSizeClassName = getPropStyles('drawer_toggle_segment_size', segmentProps.size);
  const segmentVariantClassName = getPropStyles('drawer_toggle_segment_variant', segmentProps.variant);

  const segmentComponentClassName = cn(
    segmentAnimationClassName,
    segmentColorsClassName,
    segmentSizeClassName,
    segmentVariantClassName,
    segmentClassName,
  );

  const animationClassName = getPropStyles('drawer_toggle_animation', baseProps.animation, 'default');
  const colorsClassName = getPropStyles('drawer_toggle_colors', baseProps.colors, 'default');
  const sizeClassName = getPropStyles('drawer_toggle_size', baseProps.size, 'default');
  const variantClassName = getPropStyles('drawer_toggle_variant', baseProps.variant, 'default');

  const componentClassName = cn(animationClassName, colorsClassName, sizeClassName, variantClassName, className);

  return (
    <>
      {children ?? (
        <Hamburger
          options={{
            $segment: {
              className: segmentComponentClassName,
              ...segmentProps,
            },
            className: componentClassName,
            ...baseProps,
          }}
          {...{ isOpen, onChange }}
          {...props}
        />
      )}
    </>
  );
};
