import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface NavigationSearchProps extends ComponentProps<'form'> {
  colors?: ClassNameProp;
  size?: ClassNameProp<'bar' | 'drawer'>;
  variant?: ClassNameProp<'bar' | 'drawer'>;
}

export const NavigationSearchComponent: Component<NavigationSearchProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('search_colors', colors);
  const sizeClassName = getPropStyles('search_size', size, 'bar');
  const variantClassName = getPropStyles('search_variant', variant, 'bar');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <form className={componentClassName} action="/search/" method="GET" {...props}>
      {children}
    </form>
  );
};
