import { ClassNameProvider } from '@/styles/TextField';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ChangeEventHandler, ReactNode, useId, useState } from 'react';
import { TextField, TextFieldProps } from './TextField';
import { TextFieldCaptionProps } from './TextField.Caption';
import { TextFieldGroupProps } from './TextField.Group';
import { TextFieldIconProps } from './TextField.Icon';
import { TextFieldInputProps } from './TextField.Input';
import { TextFieldLabelProps } from './TextField.Label';

export interface StandaloneTextFieldProps extends StandaloneComponentProps {
  caption?: ReactNode;
  icon?: TextFieldIconProps['name'];
  label?: ReactNode;
  name?: TextFieldInputProps['name'];
  placeholder?: TextFieldInputProps['placeholder'];
  status?: TextFieldProps['status'];
  options?: Omit<TextFieldProps, 'status'> & {
    $caption?: TextFieldCaptionProps;
    $group?: TextFieldGroupProps;
    $icon?: Omit<TextFieldIconProps, 'name'>;
    $input?: Omit<TextFieldInputProps, 'name' | 'placeholder'>;
    $label?: TextFieldLabelProps;
  };
}

export const StandaloneTextField: StandaloneComponent<StandaloneTextFieldProps> = ({
  caption,
  icon,
  label,
  name,
  placeholder,
  status,
  options,
  ...props
}) => {
  const [filled, setFilled] = useState<'' | undefined>(undefined);

  const {
    $caption: captionProps,
    $group: groupProps,
    $icon: iconProps,
    $input: inputProps,
    $label: labelProps,
    ...baseProps
  } = options ?? {};

  const id = useId();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilled(event.target.value ? '' : undefined);
    inputProps?.onChange?.(event);
  };

  return (
    <ClassNameProvider value={baseProps}>
      <TextField data-filled={filled} {...{ status }} {...baseProps} {...props}>
        <TextField.Group {...groupProps}>
          {label && (
            <TextField.Label htmlFor={id} {...labelProps}>
              {label}
            </TextField.Label>
          )}
          <TextField.Group {...groupProps}>
            {icon && <TextField.Icon name={icon} {...iconProps} />}
            <TextField.Input {...{ id, name, placeholder }} {...inputProps} onChange={onChange} />
          </TextField.Group>
        </TextField.Group>
        {caption && <TextField.Caption {...captionProps}>{caption}</TextField.Caption>}
      </TextField>
    </ClassNameProvider>
  );
};
