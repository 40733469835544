import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface NavigationSoMeHeadlineProps extends ComponentProps<'header'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const NavigationSoMeHeadlineComponent: Component<NavigationSoMeHeadlineProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('soMe_headline_colors', colors);
  const sizeClassName = getPropStyles('soMe_headline_size', size);
  const variantClassName = getPropStyles('soMe_headline_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <header className={componentClassName} {...props}>
      {children}
    </header>
  );
};
