import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useMemo } from 'react';
import { Hamburger, HamburgerProps } from './Hamburger';
import { HamburgerSegmentProps } from './Hamburger.Segment';
import { HamburgerToggleProps } from './Hamburger.Toggle';

export type HamburgerAnimation = 'default' | 'alternative';

export interface StandaloneHamburgerProps extends StandaloneComponentProps {
  content?: React.ReactNode;
  isOpen?: boolean;
  onChange?: HamburgerToggleProps['onChange'];
  segments?: number;
  options?: HamburgerProps & {
    $segment?: HamburgerSegmentProps;
    $toggle?: Omit<HamburgerToggleProps, 'onChange'>;
  };
}

export const StandaloneHamburger: StandaloneComponent<StandaloneHamburgerProps> = ({
  content,
  isOpen,
  onChange,
  segments,
  options,
  ...props
}) => {
  const { $segment, animation, colors, size, variant, ...baseProps } = options || {};

  const defaultContent = useMemo(
    () =>
      Array.from({ length: segments ?? 4 }).map((_, index) => (
        <Hamburger.Segment key={index} {...{ animation, colors, size, variant }} {...$segment} />
      )),
    [$segment, animation, colors, segments, size, variant],
  );

  return (
    <Hamburger data-open={isOpen ? '' : undefined} {...{ animation, colors, size, variant }} {...baseProps} {...props}>
      <Hamburger.Toggle onChange={onChange} checked={isOpen} {...{ variant }} />
      {content ?? defaultContent}
    </Hamburger>
  );
};
