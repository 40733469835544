/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTextField as ResolvedStandaloneTextField } from 'base/components/standalone/TextField';

export const TextField = ResolvedStandaloneTextField;

export type TextFieldProps = PropsFromComponent<typeof TextField>;

export const TextFieldWith = (extras: DynamicStandaloneExtras): typeof TextField => {
    return function TextField(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextField');
        return <ResolvedStandaloneTextField {...mergeProps({ options: { theme } }, props)} />;
    }
}