import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { FooterColumnComponent } from './Footer.Column';
import { FooterCopyright } from './Footer.Copyright';
import { FooterDescriptionComponent } from './Footer.Description';
import { FooterEditorsComponent } from './Footer.Editors';
import { FooterList } from './Footer.List';
import { FooterListItemComponent } from './Footer.List.Item';
import { FooterListTitleComponent } from './Footer.List.Title';
import { FooterLogoComponent } from './Footer.Logo';
import { FooterRowComponent } from './Footer.Row';
import { FooterSitesComponent } from './Footer.Sites';
import { FooterSitesItemComponent } from './Footer.Sites.Item';
import { FooterSitesTitleComponent } from './Footer.Sites.Title';
import { FooterSoMeComponent } from './Footer.SoMe';
import { FooterSoMeGroupComponent } from './Footer.SoMe.Group';
import { FooterSoMeHeadlineComponent } from './Footer.SoMe.Headline';

export type FooterProps = ComponentProps<'footer'>;

const FooterComponent: Component<FooterProps> = ({ variant, size, colors, children, ...props }) => {
  const className = componentClassName('', { variant, size, colors });

  return <footer {...mergeProps({ className }, props)}>{children}</footer>;
};

export const Footer = Object.assign(FooterComponent, {
  Column: FooterColumnComponent,
  Row: FooterRowComponent,
  Description: FooterDescriptionComponent,
  Editors: FooterEditorsComponent,
  Logo: FooterLogoComponent,
  Copyright: FooterCopyright,
  List: Object.assign(FooterList, {
    Item: FooterListItemComponent,
    Title: FooterListTitleComponent,
  }),
  Sites: Object.assign(FooterSitesComponent, {
    Item: FooterSitesItemComponent,
    Title: FooterSitesTitleComponent,
  }),
  SoMe: Object.assign(FooterSoMeComponent, {
    Group: FooterSoMeGroupComponent,
    Headline: FooterSoMeHeadlineComponent,
  }),
});
