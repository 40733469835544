import { Link } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import getCurrentBrand from '@/utils/getCurrentBrand';
import { ReactNode } from 'react';
import { Footer } from './Footer';
import { FooterSitesProps } from './Footer.Sites';
import { ALLER_MEDIA_SITES, ALLER_MEDIA_VIDEO_SITES, Site } from './Footer.Sites.Config';
import { FooterSitesItemProps } from './Footer.Sites.Item';
import { FooterSitesTitleProps } from './Footer.Sites.Title';

export interface StandaloneFooterProps
  extends StandaloneComponentProps<
    typeof Footer,
    {
      $sites?: FooterSitesProps & {
        $item?: FooterSitesItemProps;
        $title?: FooterSitesTitleProps;
      };
    }
  > {
  soMe?: ReactNode;
  logo?: ReactNode;
  editors?: ReactNode;
  description?: ReactNode;
  columns?: ReactNode;
  copyright?: ReactNode;
  sites?: {
    title?: ReactNode;
    items?: Site[];
  };
  videoSites?: {
    title?: ReactNode;
    items?: Site[];
  };
}

export const StandaloneFooter: StandaloneComponent<StandaloneFooterProps> = ({
  soMe,
  logo,
  description,
  editors,
  columns,
  sites,
  videoSites,
  copyright,
  options,
}) => {
  const {
    $column,
    $row,
    $copyright,
    $description,
    $editors,
    $logo,
    $soMe,
    $sites: sitesProps,
    ...baseProps
  } = options ?? {};

  const { $item: $sitesItem, $title: $sitesTitle, ...$sites } = sitesProps ?? {};

  const { name: currentSite } = getCurrentBrand();

  const defaultSites = ALLER_MEDIA_SITES.filter(({ name }) => name !== currentSite);

  return (
    <Footer {...baseProps}>
      <Footer.Row {...$row}>
        <Footer.Column {...$column}>
          <Footer.Logo {...$logo}>{logo}</Footer.Logo>
          <Footer.Description {...$description}>{description}</Footer.Description>
          <Footer.Editors {...$editors}>{editors}</Footer.Editors>
          {soMe}
        </Footer.Column>

        {columns}
      </Footer.Row>

      <Footer.Sites.Title {...$sitesTitle}>{sites?.title ?? 'Fler titlar från Aller Media'}</Footer.Sites.Title>
      <Footer.Sites {...$sites}>
        {(sites?.items ?? defaultSites).map(({ name, url }, index) => (
          <Footer.Sites.Item key={index} {...$sitesItem}>
            <Link href={url} target="_blank" content={name} aria-label={`Länk till ${name}`} />
          </Footer.Sites.Item>
        ))}
      </Footer.Sites>

      <Footer.Sites.Title {...$sitesTitle}>{videoSites?.title ?? 'Aller Medias videosajter'}</Footer.Sites.Title>
      <Footer.Sites {...$sites}>
        {(videoSites?.items ?? ALLER_MEDIA_VIDEO_SITES).map(({ name, url }, index) => (
          <Footer.Sites.Item key={index} {...$sitesItem}>
            <Link href={url} target="_blank" content={name} aria-label={`Länk till ${name} videosida`} />
          </Footer.Sites.Item>
        ))}
      </Footer.Sites>

      <Footer.Copyright {...$copyright}>{copyright}</Footer.Copyright>
    </Footer>
  );
};
