import { useClassName } from '@/styles/TextField';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { TextFieldCaptionComponent } from './TextField.Caption';
import { TextFieldGroupComponent } from './TextField.Group';
import { TextFieldIconComponent } from './TextField.Icon';
import { TextFieldInputComponent } from './TextField.Input';
import { TextFieldLabelComponent } from './TextField.Label';
import { mergeProps } from '@/utils/merge';

export interface TextFieldProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  status?: 'default' | 'error';
  variant?: ClassNameProp<'default' | 'alternative'>;
}

const TextFieldComponent: Component<TextFieldProps> = ({ children, status, ...props }) => {
  const className = useClassName('', props, {
    defaults: { color: 'primary' },
  });

  return (
    <div data-status={status} {...mergeProps({ className }, props)}>
      {children}
    </div>
  );
};

export const TextField = Object.assign(TextFieldComponent, {
  Caption: TextFieldCaptionComponent,
  Group: TextFieldGroupComponent,
  Icon: TextFieldIconComponent,
  Input: TextFieldInputComponent,
  Label: TextFieldLabelComponent,
});
