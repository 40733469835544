/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneBase as ResolvedStandaloneBase } from 'base/components/standalone/Base';

export const Base = ResolvedStandaloneBase;

export type BaseProps = PropsFromComponent<typeof Base>;

export const BaseWith = (extras: DynamicStandaloneExtras): typeof Base => {
    return function Base(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Base');
        return <ResolvedStandaloneBase {...mergeProps({ options: { theme } }, props)} />;
    }
}