import isClient from './isClient';
import logger from './logger';

export const showOneTrustConsent = () => {
  if (isClient() && window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay();
  } else {
    logger.info('window.OneTrust is not available');
  }
};
