import { getSite } from '.';
import { getBrandBy } from './getBrandBy';

const site = getSite();

export const getCurrentBrand = () => {
  const brand = getBrandBy({ slug: site });

  if (!brand) {
    throw new Error(`Brand not found for site ${site}`);
  }

  return brand;
};

export default getCurrentBrand;
