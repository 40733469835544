import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type FooterSitesTitleProps = ComponentProps<'h4'>;

export const FooterSitesTitleComponent: Component<FooterSitesTitleProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const className = componentClassName('sites_title', { variant, size, colors });

  return <h4 {...mergeProps({ className }, props)}>{children}</h4>;
};
