/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneIcon as ResolvedStandaloneIcon } from 'base/components/standalone/Icon';

export const Icon = ResolvedStandaloneIcon;

export type IconProps = PropsFromComponent<typeof Icon>;

export const IconWith = (extras: DynamicStandaloneExtras): typeof Icon => {
    return function Icon(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Icon');
        return <ResolvedStandaloneIcon {...mergeProps({ options: { theme } }, props)} />;
    }
}