/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneFooter as ResolvedStandaloneFooter } from 'sites/svenskdam/components/standalone/Footer';

export const Footer = ResolvedStandaloneFooter;

export type FooterProps = PropsFromComponent<typeof Footer>;

export const FooterWith = (extras: DynamicStandaloneExtras): typeof Footer => {
    return function Footer(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Footer');
        return <ResolvedStandaloneFooter {...mergeProps({ options: { theme } }, props)} />;
    }
}