import { useClassName } from '@/styles/TextField';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export interface TextFieldGroupProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const TextFieldGroupComponent: Component<TextFieldGroupProps> = ({ children, ...props }) => {
  const className = useClassName('group', props, { defaults: { colors: 'primary' } });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
