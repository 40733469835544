/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "text-white bg-primary-700 border-primary-700 hover:bg-primary-600 active:bg-primary-800 focus:bg-primary-800 focus:ring-royal-blue-200",
  "colors_secondary": "text-black bg-gray-100 border-gray-100 hover:bg-gray-200 hover:border-gray-200 active:bg-gray-300 active:border-gray-300 focus:bg-gray-300 focus:border-gray-300 focus:ring-royal-blue-200",
  "size_small": "h-8 px-5 py-2 text-headline-3xs gap-1.5",
  "size_medium": "h-9 px-6 py-2.25 text-headline-2xs gap-2.5",
  "size_large": "h-12 px-6 py-3.5 text-headline-2xs gap-2.5",
  "variant_default": "inline-flex items-center justify-center rounded-full ring-0 active:ring-0 hover:ring-0 focus:ring-4 outline-none focus:outline-none transition-all duration-200 ease-in-out border"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;