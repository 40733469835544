import { useClassName } from '@/styles/TextField';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export interface TextFieldLabelProps extends ComponentProps<'label'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const TextFieldLabelComponent: Component<TextFieldLabelProps> = ({ children, ...props }) => {
  const className = useClassName('label', props, { defaults: { colors: 'primary' } });

  return <label {...mergeProps({ className }, props)}>{children}</label>;
};
