/** @type {import('lib/utils/getBrandBy').Brand[]} */

module.exports = [
  {
    id: 2,
    name: 'Allas',
    slug: 'allas',
    host: 'allas.se',
  },
  {
    id: 4,
    name: 'Femina',
    slug: 'femina',
    host: 'femina.se',
  },
  {
    id: 5,
    name: 'Motherhood',
    slug: 'motherhood',
    host: 'motherhood.se',
  },
  {
    id: 7,
    name: 'Baaam',
    slug: 'baaam',
    host: 'baaam.se',
    deprecated: true,
  },
  {
    id: 10,
    name: 'MåBra',
    slug: 'mabra',
    host: 'mabra.com',
  },
  {
    id: 11,
    name: 'Hänt',
    slug: 'hant',
    host: 'hant.se',
  },
  {
    id: 12,
    name: 'Svensk Dam',
    slug: 'svenskdam',
    host: 'svenskdam.se',
  },
  {
    id: 14,
    name: 'Elle',
    slug: 'elle',
    host: 'elle.se',
  },
  {
    id: 16,
    name: 'Residence Magazine',
    slug: 'residence',
    host: 'residencemagazine.se',
  },
  {
    id: 85,
    name: 'Frida',
    slug: 'frida',
    host: 'frida.se',
    deprecated: true,
  },
  {
    id: 90,
    name: 'Lynx',
    slug: 'lynx',
    host: 'lynx.aller.se',
  },
];
