import cn from 'lib/utils/cn';
import { mergeWith } from 'lodash-es';
import { isArray, isNotUndefined } from 'typesafe-utils';

type MergeWithCustomizer = (value: any, srcValue: any, key: string, object: any, source: any) => any;

const options: MergeWithCustomizer = (x, y, key, object, source) => {
  if (key === 'className') {
    return cn(x, y);
  }

  if (isArray(y)) {
    return y;
  }
};

const getMergeGroups = <T extends {}>(object?: Partial<T>, source?: T, ...fragments: Partial<T>[]) =>
  [object, source, ...fragments].reduce((result, current, index) => {
    const group = Math.floor(index / 3);
    result[group] = [...(result[group] ?? []), current].filter(isNotUndefined);
    return result;
  }, [] as Partial<T>[][]);

const mergeGroups = <T extends {}>(groups: Partial<T>[][], options?: MergeWithCustomizer) =>
  groups.reduce((result, current) => {
    const [a, b, c] = current;
    return mergeWith(result, a, b, c, options);
  }, {} as T);

/** @deprecated Use `mergeOptions` from `lib/utils/merge` */
export const mergeOptions = <T extends {}>(object?: Partial<T>, source?: T, ...fragments: Partial<T>[]) => {
  const groups = getMergeGroups(object, source, ...fragments);

  return mergeGroups(groups, options) as T;
};

/** @deprecated Use `mergeProps` from `lib/utils/merge` */
export const mergeProps = <T extends {}>(object?: Partial<T>, source?: T, ...fragments: Partial<T>[]) => {
  const groups = getMergeGroups(object, source, ...fragments);

  const { animation, colors, size, variant, theme, ...rest } = mergeGroups(groups, options) as any;

  return rest as T;
};

export default mergeProps;
