import { useClassName } from '@/styles/TextField';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export interface TextFieldCaptionProps extends ComponentProps<'span'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const TextFieldCaptionComponent: Component<TextFieldCaptionProps> = ({ children, ...props }) => {
  const className = useClassName('caption', props, { defaults: { colors: 'primary' } });

  return <span {...mergeProps({ className }, props)}>{children}</span>;
};
