/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneMain as ResolvedStandaloneMain } from 'base/components/standalone/Main';

export const Main = ResolvedStandaloneMain;

export type MainProps = PropsFromComponent<typeof Main>;

export const MainWith = (extras: DynamicStandaloneExtras): typeof Main => {
    return function Main(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Main');
        return <ResolvedStandaloneMain {...mergeProps({ options: { theme } }, props)} />;
    }
}