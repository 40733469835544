import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface NavigationBarProps extends ComponentProps<'div'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const NavigationBarComponent: Component<NavigationBarProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('bar_colors', colors);
  const sizeClassName = getPropStyles('bar_size', size);
  const variantClassName = getPropStyles('bar_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};
