import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type FooterListItemProps = ComponentProps<'li'>;

export const FooterListItemComponent: Component<FooterListItemProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const className = componentClassName('list_item', { variant, size, colors });

  return <li {...mergeProps({ className }, props)}>{children}</li>;
};
