/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneHamburger as ResolvedStandaloneHamburger } from 'base/components/standalone/Hamburger';

export const Hamburger = ResolvedStandaloneHamburger;

export type HamburgerProps = PropsFromComponent<typeof Hamburger>;

export const HamburgerWith = (extras: DynamicStandaloneExtras): typeof Hamburger => {
    return function Hamburger(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Hamburger');
        return <ResolvedStandaloneHamburger {...mergeProps({ options: { theme } }, props)} />;
    }
}