import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type FooterColumnProps = ComponentProps<'section'>;

export const FooterColumnComponent: Component<FooterColumnProps> = ({ variant, size, colors, children, ...props }) => {
  const className = componentClassName('column', { variant, size, colors });

  return <section {...mergeProps({ className }, props)}>{children}</section>;
};
