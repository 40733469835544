import { useClassName } from '@/styles/TextField';
import { ClassNameProp, ComponentProps, ComponentWithRef } from '@/types/component';
import React from 'react';
import { mergeProps } from '@/utils/merge';

export interface TextFieldInputProps extends Omit<ComponentProps<'input'>, 'size'> {
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const TextFieldInputComponent: ComponentWithRef<HTMLInputElement, TextFieldInputProps> = React.forwardRef(
  ({ size, ...props }, ref) => {
    const className = useClassName(
      'input',
      { ...props, size },
      {
        defaults: { colors: 'primary', size: 'default' },
      }
    );
    return <input {...{ ref }} {...mergeProps({ className }, props)} />;
  }
);
