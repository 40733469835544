import c from '@/styles/Hamburger';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';
import { HamburgerAnimation } from '.';
import { HamburgerSegmentComponent } from './Hamburger.Segment';
import { HamburgerToggleComponent } from './Hamburger.Toggle';

export interface HamburgerProps extends ComponentProps<'label'> {
  animation?: ClassNameProp<HamburgerAnimation>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

const HamburgerComponent: Component<HamburgerProps> = ({
  animation,
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const animationClassName = getPropStyles('animation', animation, 'default');
  const colorsClassName = getPropStyles('colors', colors, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');
  const variantClassName = getPropStyles('variant', variant, 'default');

  const componentClassName = cn(
    'cursor-pointer',
    animationClassName,
    colorsClassName,
    sizeClassName,
    variantClassName,
    className
  );

  return (
    <label className={componentClassName} {...props}>
      {children}
    </label>
  );
};

export const Hamburger = Object.assign(HamburgerComponent, {
  Segment: HamburgerSegmentComponent,
  Toggle: HamburgerToggleComponent,
});
