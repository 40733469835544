import { componentClassName } from '@/styles/Footer';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export type FooterCopyrightProps = ComponentProps<'div'>;

export const FooterCopyrightComponent: Component<FooterCopyrightProps> = ({
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const className = componentClassName('copyright', { variant, size, colors });

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const FooterCopyright = FooterCopyrightComponent;
