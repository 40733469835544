export interface Site {
  name: string;
  url: string;
}

export const ALLER_MEDIA_SITES: Site[] = [
  {
    name: 'Elle Sverige',
    url: 'https://www.elle.se',
  },
  {
    name: 'Hänt',
    url: 'https://www.hant.se',
  },
  {
    name: 'Svensk Damtidning',
    url: 'https://www.svenskdam.se',
  },
  {
    name: 'Femina',
    url: 'https://www.femina.se',
  },
  {
    name: 'MåBra',
    url: 'https://www.mabra.com',
  },
  {
    name: 'Allas',
    url: 'https://www.allas.se',
  },
  {
    name: 'Recept',
    url: 'https://www.recept.se',
  },
  {
    name: 'Motherhood',
    url: 'https://www.motherhood.se',
  },
  {
    name: 'Residence Magazine',
    url: 'https://www.residencemagazine.se',
  },
];

export const ALLER_MEDIA_VIDEO_SITES: Site[] = [
  {
    name: 'ELLE Sverige',
    url: 'https://www.elle.se/video/',
  },
  {
    name: 'Hänt',
    url: 'https://www.hant.se/video/',
  },
  {
    name: 'Svensk Damtidning',
    url: 'https://www.svenskdam.se/video/',
  },
  {
    name: 'Femina',
    url: 'https://www.femina.se/video/',
  },
  {
    name: 'MåBra',
    url: 'https://www.mabra.com/video/',
  },
  {
    name: 'Allas',
    url: 'https://www.allas.se/video/',
  },
  {
    name: 'Residence Magazine',
    url: 'https://www.residencemagazine.se/video/',
  },
];
