import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Icon, IconProps } from './Icon';

export interface StandaloneIconProps extends StandaloneComponentProps {
  name?: IconProps['name'];
  options?: Omit<IconProps, 'name'>;
}

export const StandaloneIcon: StandaloneComponent<StandaloneIconProps> = ({ name, options, ...props }) => {
  const { ...baseProps } = options ?? {};

  if (!name) {
    return null;
  }

  return <Icon {...{ name }} {...baseProps} {...props} />;
};
