import { componentClassName } from '@/styles/Icon';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import logger from 'lib/utils/logger';
import mergeProps from 'lib/utils/mergeProps';
import { iconsDictionary } from './Icon.dictionary';

export interface IconProps extends ComponentProps<'svg'> {
  name: keyof typeof iconsDictionary;
  size?: ClassNameProp<'small' | 'medium' | 'large' | 'extraLarge'>;
}

const IconComponent: Component<IconProps> = ({ name, colors, size, variant, theme, ...props }) => {
  const Icon = iconsDictionary[name];

  if (!Icon) {
    logger.error(`Icon "${name}" not found`);
    return null;
  }

  const className = componentClassName('', { colors, size, variant }, { defaults: { size: 'small' }, theme });

  return <Icon {...mergeProps({ className }, props)} />;
};

export const Icon = IconComponent;
