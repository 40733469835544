import { Icon } from '@/components/Icon';
import c from '@/styles/Navigation';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { useComponentsState } from 'lib/hooks/useComponentsState';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface NavigationSearchToggleProps extends ComponentProps<'div'> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const NavigationSearchToggleComponent: Component<NavigationSearchToggleProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const {
    Navigation: {
      $drawer: { isVisible, toggle },
    },
  } = useComponentsState();

  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('search_toggle_colors', colors);
  const sizeClassName = getPropStyles('search_toggle_size', size);
  const variantClassName = getPropStyles('search_toggle_variant', variant);
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  const onClick = toggle;

  return (
    <div className={componentClassName} onClick={onClick} {...props}>
      {children ?? <Icon name={isVisible ? 'searchOff' : 'search'} options={{ size: 'medium' }} />}
    </div>
  );
};
