import c from '@/styles/Hamburger';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from 'lib/utils/cn';
import { getPropStylesFactory } from 'lib/utils/getPropStylesFactory';

export interface HamburgerSegmentProps extends ComponentProps<'hr'> {
  animation?: ClassNameProp<'default' | 'alternative'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const HamburgerSegmentComponent: Component<HamburgerSegmentProps> = ({
  animation,
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const animationClassName = getPropStyles('segment_animation', animation, 'default');
  const colorsClassName = getPropStyles('segment_colors', colors, 'default');
  const sizeClassName = getPropStyles('segment_size', size, 'default');
  const variantClassName = getPropStyles('segment_variant', variant, 'default');

  const componentClassName = cn(animationClassName, colorsClassName, sizeClassName, variantClassName, className);

  return <hr className={componentClassName} {...props} />;
};
